import * as React from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"
import "../styles/styles.sass"
import Header from "../components/header"
import Footer from "../components/footer"

const SuccessPage = () => {
  return (
  <main className="mercuryView noticeView">
    <Seo
      title="Phil Amour — Message Sent"
    />
    
    <Header headerType="detail" />

    <div className="mercuryContent">
      <section className="noticeMessage">
        <h1 className="messageTitle">Thank you for reaching out!</h1>
        <p className="messageSubtitle">I will get back to you as soon as I see your message to discuss the next steps.</p>

        <Link  to="/" className="button large primary">
          <div className="buttonTitle">Go to Home Page</div>
        </Link>
      </section>
    </div>
    
    <Footer />
  </main>
  )
}

export default SuccessPage
